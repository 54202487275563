import React from "react";
import { Container } from "react-bootstrap";
import Layout from "../components/layout";

export default function TermsConditions() {
  return (
    <Layout>
      <Container className="body-margin">
        <div className="card">
          <div className="image"></div>
          <div className="content">
            <div className="text-center mb-3">
              <h1>Privacy Policy</h1>
            </div>
            <div>
              <article>
                <aside>
                  <strong>Privacy Policy</strong>
                  <br />
                  Fjord Advisors Limited is committed to the appropriate
                  protection and use of personal information that has been
                  collected through its website and is the data controller in
                  respect of this data.
                  <br />
                  <br />
                  Fjord Advisors Limited is authorised and regulated by the UK
                  Financial Conduct Authority with number 538629 and registered
                  in England and Wales with company registration number
                  07472781. Our registered office is at Hainton Lodge, 138 High
                  Street, Sutton Courtenay, Oxfordshire, OX14 4AX, England and
                  we can be contacted by email at&nbsp;
                  <a href="mailto:info@fjordcap.com">info@fjordcap.com</a>
                  <br />
                  <br />
                  Please review this Privacy Policy to learn more about how we
                  collect, use, share and protect the personal information that
                  we have obtained.
                  <br />
                  <br />
                  <strong>Collection and use of personal information</strong>
                  <br />
                  We obtain personal information about you only if you choose to
                  provide it. By registering and/or submitting personal
                  information on our website, you are also agreeing to the use
                  of this information in accordance with this Privacy Policy.
                  Your personal information is not used for other purposes,
                  unless we obtain your permission, or unless otherwise required
                  or permitted by law or professional standards. You can choose
                  not to give us personal information. We may need to collect
                  personal information by law, or to enter into or fulfil a
                  contract we have with you. If you choose not to give us this
                  personal information, it may mean that we cannot fulfil our
                  contract with you, or do what we are required to do by law.
                  <br />
                  <br />
                  <strong>Cookies</strong>
                  <br />
                  In some instances, Fjord Advisors Limited automatically
                  collects certain types of information when you visit our
                  website and through emails that we may exchange. Automated
                  technologies may include the use of web server logs to collect
                  IP addresses, "cookies", and web beacons. The collection of
                  this information will allow us to better understand and
                  improve the performance, usability and effectiveness of Fjord
                  Advisors Limited website.
                  <br />
                  <br />
                  You can set your browser to refuse all or some browser
                  cookies, or to alert you when websites set or access cookies.
                  If you disable or refuse cookies, please note that some parts
                  of this website may become inaccessible or not function
                  properly.
                  <br />
                  <br />
                  <strong>
                    Why we use your data and the legal basis for it
                  </strong>
                  <br />
                  Data protection law says we must have a reason to use your
                  data. This could be to fulfil a contract we have with you,
                  when we have a legal duty to do so, where it is in our
                  &lsquo;legitimate interest&rsquo; or where you give us your
                  consent.
                  <br />
                  <br />
                  When we have a commercial reason to use your information, this
                  is called a &lsquo;legitimate interest&rsquo;. If we are going
                  to rely on it as the reason for using your data we must tell
                  you.
                  <br />
                  <br />
                  We have set out below, in a table format, a description of all
                  the ways we plan to use your personal data, and which of the
                  legal bases we rely on to do so. We have also identified what
                  our legitimate interests are where appropriate.
                  <br />
                  <br />
                  Note that we may process your personal data for more than one
                  lawful ground depending on the specific purpose for which we
                  are using your data. If you need details about the specific
                  legal ground we are relying on to process your personal data
                  where more than one ground has been set out in the table
                  below.
                  <br />
                  <br />
                  <table border="1" cellspacing="0" cellpadding="10">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Purpose/Activity</strong>
                        </td>
                        <td>
                          <strong>Type of data</strong>
                        </td>
                        <td>
                          <strong>
                            Lawful basis for processing including basis of
                            legitimate interest
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>To register you as a new customer</td>
                        <td>
                          (a) Identity
                          <br />
                          (b) Contact
                        </td>
                        <td>Performance of a contract with you</td>
                      </tr>
                      <tr>
                        <td>
                          To manage our relationship with you which will include
                          notifying you about changes to our terms or privacy
                          policy
                        </td>
                        <td>
                          (a) Identity
                          <br />
                          (b) Contact
                          <br />
                          (c) Profile
                          <br />
                          (d) Marketing and Communications
                        </td>
                        <td>
                          (a) Performance of a contract with you
                          <br />
                          (b) Necessary to comply with a legal obligation
                          <br />
                          (c) Necessary for our legitimate interests (to keep
                          our records updated and to study how customers use our
                          products/services)
                        </td>
                      </tr>
                      <tr>
                        <td>
                          To administer and protect our business and this
                          website (including troubleshooting, data analysis,
                          testing, system maintenance, support, reporting and
                          hosting of data)
                        </td>
                        <td>
                          (a) Identity
                          <br />
                          (b) Contact
                          <br />
                          (c) Technical
                        </td>
                        <td>
                          (a) Necessary for our legitimate interests (for
                          running our business, provision of administration and
                          IT services, network security, to prevent fraud and in
                          the context of a business reorganisation or group
                          restructuring exercise)
                          <br />
                          (b) Necessary to comply with a legal obligation
                        </td>
                      </tr>
                      <tr>
                        <td>
                          To deliver relevant website content and advertisements
                          to you and measure or understand the effectiveness of
                          the advertising we serve to you
                        </td>
                        <td>
                          (a) Identity
                          <br />
                          (b) Contact
                          <br />
                          (c) Profile
                          <br />
                          (d) Usage
                          <br />
                          (e) Marketing and Communications
                          <br />
                          (f) Technical
                        </td>
                        <td>
                          Necessary for our legitimate interests (to study how
                          customers use our products/services, to develop them,
                          to grow our business and to inform our marketing
                          strategy)
                          <br />
                          or Consent
                        </td>
                      </tr>
                      <tr>
                        <td>
                          To use data analytics to improve our website,
                          products/services, marketing, customer relationships
                          and experiences
                        </td>
                        <td>
                          (a) Technical
                          <br />
                          (b) Usage
                        </td>
                        <td>
                          Necessary for our legitimate interests (to define
                          types of customers for our products and services, to
                          keep our website updated and relevant, to develop our
                          business and to inform our marketing strategy)
                        </td>
                      </tr>
                      <tr>
                        <td>
                          To make suggestions and recommendations to you about
                          matters that may be of interest to you
                        </td>
                        <td>
                          (a) Identity
                          <br />
                          (b) Contact
                          <br />
                          (c) Technical
                          <br />
                          (d) Usage
                          <br />
                          (e) Profile
                        </td>
                        <td>
                          Necessary for our legitimate interests (to develop our
                          products/services and grow our business)
                          <br />
                          or Consent
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  &nbsp;
                  <br />
                  <strong>YOUR RIGHTS</strong>
                  <br />
                  You have these rights:
                  <br />
                  <ul>
                    <li>
                      to get a copy of the personal information we hold about
                      you;
                    </li>
                    <li>
                      to question any information we have about you that you
                      think is incorrect, so that we can take reasonable steps
                      to check and correct it;
                    </li>
                    <li>
                      to share your data with outside companies, as a digital
                      file, so that you can keep and use it yourself, or give it
                      to other organisations;
                    </li>
                    <li>
                      to object to us keeping or using your personal data;
                    </li>
                    <li>
                      to ask us to delete, remove or stop using your personal
                      data if there is no need for us to keep it; and
                    </li>
                    <li>
                      to restrict the use of your data, so that it can only be
                      used for certain things, such as legal claims or to
                      exercise legal rights.
                    </li>
                  </ul>
                  There may be legal or other official reasons why we need to
                  keep or use your data.
                  <br />
                  To exercise any of these rights please contact us at&nbsp;
                  <u>
                    <a href="mailto:info@fjordcap.com">info@fjordcap.com</a>
                  </u>
                  <br />
                  <br />
                  <strong>Where we store and transfer your data</strong>
                  <br />
                  The data that we collect from you may be transferred to, and
                  stored at, a destination outside the European Economic Area
                  (&ldquo;EEA&rdquo;). We will only send your data outside of
                  the EEA to comply with a legal duty or work with our suppliers
                  in providing products and services to you. If we do transfer
                  your personal information outside the EEA to our suppliers, we
                  will make sure that it is protected to the same extent as in
                  the EEA. We will use one of these safeguards:
                  <br />
                  <ul>
                    <li>
                      Transfer it to a non-EEA country where the European
                      Commission has deemed that such country provides an
                      adequate level of protection for personal data.
                    </li>
                    <li>
                      Put in place a specific contract approved by the European
                      Commission with the recipient that means they must protect
                      it to the same standards as the EEA.
                    </li>
                    <li>
                      Transfer it to organisations that are part of Privacy
                      Shield. This is a framework that sets privacy standards
                      for data sent between the US and EU countries. It makes
                      sure those standards are similar to what is used within
                      the EEA
                    </li>
                  </ul>
                  <br />
                  <strong>Children Privacy Protection</strong>
                  <br />
                  Fjord Advisors Limited understands the importance of
                  protecting children's privacy, especially in an online
                  environment. Our website is not intentionally designed for or
                  directed at children under the age of 13. It is our policy
                  never to knowingly collect or maintain information about
                  anyone under the age of 13.
                  <br />
                  <br />
                  <strong>Data security and integrity</strong>
                  <br />
                  Fjord Advisors Limited has put appropriate security policies
                  and procedures in place to protect your personal information
                  from being accidentally lost, misused, altered, or destroyed.
                  Despite our best efforts, however, security cannot be
                  absolutely guaranteed against all threats. We limit access to
                  your personal information to employees, agents, contractors
                  and other third parties who have a need to know. Those
                  individuals who have access to the data are required to
                  maintain the confidentiality of such information and will only
                  process your personal data on our instructions.
                  <br />
                  <br />
                  We have put in place procedures to deal with any suspected
                  personal data breach and will notify you and any applicable
                  regulator of a breach where we are legally required to do so.
                  <br />
                  <br />
                  <strong>Third-party links</strong>
                  <br />
                  This website may include links to third-party websites,
                  plug-ins and applications. Clicking on those links or enabling
                  those connections may allow third parties to collect or share
                  data about you. We do not control these third-party websites
                  and are not responsible for their privacy statements. When you
                  leave our website, we encourage you to read the privacy policy
                  of every website you visit.
                  <br />
                  <br />
                  <strong>How long do we keep your data?</strong>
                  <br />
                  We will only retain your personal data for as long as
                  necessary to fulfil the purposes we collected it for,
                  including for the purpose of satisfying any legal, accounting
                  or reporting requirements. To determine the appropriate
                  retention period, we consider the amount, nature and
                  sensitivity of the personal data, the purposes for which we
                  process your personal data and whether we can achieve those
                  purposes through other means and the applicable legal
                  requirement.
                  <br />
                  <br />
                  <strong>How to withdraw your consent</strong>
                  <br />
                  You can withdraw your consent at any time. This will only
                  affect the way we use information when our reason for doing so
                  is that we have your consent. If you withdraw your consent, we
                  may not be able to provide certain products or services to
                  you. This will not affect the lawfulness of our use of the
                  data before your consent was withdrawn.
                  <br />
                  <br />
                  <strong>Changes to this policy</strong>
                  <br />
                  Fjord Advisors Limited may modify this Privacy Policy from
                  time to time to reflect our current privacy practices. We
                  encourage you to periodically review this Privacy Policy to be
                  informed about how Fjord Advisors Limited is protecting your
                  information.
                  <br />
                  <br />
                  <strong>Policy questions</strong>
                  <br />
                  Fjord Advisors Limited is committed to protecting the online
                  privacy of your personal information. If you have questions or
                  comments about our administration of your personal
                  information, please contact us at&nbsp;
                  <u>
                    <a href="mailto:info@fjordcap.com">info@fjordcap.com</a>
                  </u>
                  .&emsp;
                  <br />
                  &nbsp;
                  <br />
                  <strong>How to complain</strong>
                  <br />
                  You also have the right to complain to the regulator, and to
                  lodge an appeal if you are not happy with the outcome of a
                  complaint. In the UK this is the Information
                  Commissioner&rsquo;s Office&nbsp;
                  <a href="https://ico.org.uk/">https://ico.org.uk/</a>
                </aside>
              </article>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
